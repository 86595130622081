<template>
    <div>
        <h4 class="mt-4">Firemní údaje</h4>
        <hr/>
        <detail-item
            title="Obchodní název"
            :content="clientData.company.name"
            v-if="isCompany"
        />
        <detail-item
            title="IČ"
            :content="clientData.company.ic"
        />
        <detail-item
            title="DIČ"
            :content="clientData.company.dic"
            v-show="clientData.company.dic !== null"
        />
        <detail-item
            title="LEI"
            :content="clientData.company.lei"
        />
        <detail-item
            title="Domicil"
            v-if="taxDomicile !== null && isCompany"
            :content="taxDomicile"
        />

        <div v-if="isCompany">
            <h4 class="mt-4">Oprávnění zástupci</h4>
            <hr/>
            <representatives-list
                :client-data="clientData"
            />
        </div>
    </div>
</template>

<script>
import DetailSetBase from './DetailSetBase.vue'
import { countries } from '@cyrrus/locale-utils'
import RepresentativesList from '../RepresentativesList.vue'

export default {
    name: 'CompanySet',
    extends: DetailSetBase,
    components: {
        RepresentativesList
    },
    computed: {
        countries () {
            return countries
        },
        taxDomicile () {
            return (this.clientData.company.taxDomicile !== null) ? this.countries[this.clientData.company.taxDomicile] : null
        }
    }
}
</script>
