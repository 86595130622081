<template>
    <p class="mb-2">
        <strong>{{title}}:</strong><br/>
        <slot>
            {{contentText}}
        </slot>
    </p>
</template>

<script>
export default {
    name: 'DetailItem',
    props: {
        title: {
            type: String,
            required: true
        },
        content: {
            type: String,
            required: false
        }
    },
    computed: {
        contentText () {
            return (this.content !== null) ? `${this.content}` : 'Neuvedeno'
        }
    }
}
</script>
