<template>
    <div>
        <slot name="header">
            <h5 class="mb-3 mt-4">Investiční profily klienta</h5>
            <p v-if="!noHeader">
                <strong>Klient: {{clientInstance.fullName}}</strong>
            </p>
        </slot>
        <div class="alert alert-danger" v-if="newestList.length === 0">
            Klient nemá žádné aktivní investiční profily. Vytvořte profily vyplněním investičního dotazníku.
        </div>
        <invest-profiles-list-item
            v-for="profile in newestList"
            :key="`profile-item-${profile.uid}`"
            :profile="profile"
        />
    </div>
</template>

<script>
import InvestProfilesListItem from './InvestProfilesListItem.vue'
import { ClientData } from '@cyrrus/clientdatastructure'

export default {
    name: 'InvestProfilesList',
    components: {
        InvestProfilesListItem
    },
    props: {
        client: {
            type: Object,
            required: true
        },
        noHeader: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        clientInstance () {
            return (this.client instanceof ClientData) ? this.client : new ClientData(this.client)
        },
        newestList () {
            const list = []
            const newest = {}
            for (const profile of this.clientInstance.client.investmentProfiles) {
                if (!newest.hasOwnProperty(profile.uid) || (newest[profile.uid].added < profile.added)) {
                    newest[profile.uid] = profile
                }
            }
            for (const uid in newest) {
                list.push(newest[uid])
            }
            return list
        }
    }
}
</script>
