<template>
    <div>
        <div v-if="!loading" class="row">
            <div class="col-12 col-md-6">
                <h5>
                    Poradce (agent)
                </h5>
                <hr/>
                <p
                    v-if="agent"
                >
                    <span v-if="!agent.capitolAgent" class="badge badge-danger ml-1">Není v Capitolu</span>
                    <span v-else class="badge badge-success ml-1">Nalezen v Capitolu</span>
                    <span v-if="agentDataChanged" class="badge badge-warning ml-1">Změna os. dat</span>
                    <b-badge variant="warning" v-if="agent.bondsBroker" class="ml-1">
                        Dluhopisář
                    </b-badge>
                </p>
                <div v-if="agent !== null" class="row">
                    <div class="col-12 col-md-6">
                        <h5>Přijatá data</h5>
                        <detail-item
                            title="Jméno"
                            :content="agent.name"
                        />
                        <detail-item
                            title="Tel."
                            :content="agent.phone"
                        />
                        <detail-item
                            title="Email"
                            :content="agent.email"
                        />
                        <detail-item
                            title="Referenční kód"
                            :content="agent.referenceCode"
                        />
                        <detail-item
                            v-if="!agent.bondsBroker"
                            title="KIDC"
                            :content="agent.capitolId"
                        />
                    </div>
                    <div class="col-12 col-md-6" v-if="!!agent.capitolAgent">
                        <h5>Capitol</h5>
                        <detail-item
                            :class="{ 'text-danger': agent.name !== agent.capitolAgent.name }"
                            title="Jméno"
                            :content="agent.capitolAgent.name"
                        />
                        <detail-item
                            :class="{ 'text-danger': agent.capitolAgent.phoneNumbers.indexOf(agent.phone) === -1 }"
                            title="Tel."
                            :content="agent.capitolAgent.phoneNumbers.join(', ')"
                        />
                        <detail-item
                            :class="{ 'text-danger': agent.capitolAgent.emails.indexOf(agent.email) === -1 }"
                            title="Email"
                            :content="agent.capitolAgent.emails.join(', ')"
                        />
                        <detail-item
                            :class="{ 'text-danger': agent.referenceCode !== agent.capitolAgent.referenceCode }"
                            title="Referenční kód"
                            :content="agent.capitolAgent.referenceCode"
                        />
                        <detail-item
                            :class="{ 'text-danger': agent.capitolId !== agent.capitolAgent.capitolId }"
                            title="KIDC"
                            :content="agent.capitolAgent.capitolId"
                        />
                    </div>
                </div>
                <div v-else class="alert alert-danger text-center">
                    Nenalezeno nebo nelze načíst.
                </div>
            </div>
            <div class="col-12 col-md-6">
                <h5>Investiční zprostředkovatel</h5>
                <hr/>
                <div v-if="broker !== null">
                    <detail-item
                        title="Název"
                        :content="broker.name"
                    />
                    <detail-item
                        title="KIDC"
                        :content="broker.capitolId"
                    />
                    <detail-item
                        title="Tel."
                        :content="broker.phoneNumbers.join(', ')"
                    />
                    <detail-item
                        title="Email"
                        :content="broker.emails.join(', ')"
                    />
                </div>
                <div v-else class="alert alert-danger text-center">
                    Nenalezeno nebo nelze načíst.
                </div>
            </div>
        </div>
        <div v-else class="alert alert-info text-center">
            Načítání..
        </div>
    </div>
</template>

<script>
import DetailItem from './ClientDetailSets/DetailItem.vue'

export default {
    name: 'IzBrokerDetail',
    components: {
        DetailItem
    },
    props: {
        broker: {
            type: Object,
            required: false,
            default () {
                return null
            }
        },
        agent: {
            type: Object,
            required: false,
            default () {
                return null
            }
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        agentDataChanged () {
            if (!this.agent) {
                return false
            }
            if (!this.agent.capitolAgent) {
                return false
            }
            return (
                (this.agent.name !== this.agent.capitolAgent.name) ||
                (this.agent.lastName !== this.agent.capitolAgent.lastName) ||
                (this.agent.capitolAgent.phoneNumbers.indexOf(this.agent.phone) === -1) ||
                (this.agent.capitolAgent.emails.indexOf(this.agent.email) === -1) ||
                (this.agent.referenceCode !== this.agent.capitolAgent.referenceCode)
            )
        }
    }
}
</script>
