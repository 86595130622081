<template>
    <div class="bg-whitesmoke p-3">
        <h5 class="d-flex justify-content-between align-items-center">
            <span>{{representative.personal.fullName}}</span>
            <span>
                <b-btn
                    variant="primary"
                    size="sm"
                    @click.prevent="$emit('close')"
                >
                    <span class="iconify" data-icon="mdi:close" />
                </b-btn>
            </span>
        </h5>
        <hr/>
        <h5 class="font-weight-bold">Firemní údaje</h5>
        <detail-item
            title="Pozice"
            :content="representative.position"
        />
        <detail-item
            title="Domicil"
            :content="taxDomicile"
        />
        <detail-item
            title="Politicky exponovaná osoba"
            :content="politician"
        />

        <h5 class="mt-4 font-weight-bold">Osobní údaje</h5>
        <detail-item
            title="Jméno a příjmení"
            :content="representative.personal.fullName"
        />
        <detail-item
            title="Datum narození"
            :content="representative.personal.dateOfBirth.toLocaleDateString()"
            v-if="!isCompany"
        />
        <detail-item
            title="RČ"
            :content="(representative.personal.noBirthNumber) ? 'Nemá RČ' : representative.personal.birthNumber"
        />
        <detail-item
            v-if="!representative.personal.noBirthNumber && !isCompany"
            title="Země vydání RČ"
            :content="representative.personal.birthNumberCountryName"
        />
        <detail-item
            title="Adresa tr. pobytu / jiná adresa"
            :content="representative.address.fullAddress"
        />

        <h5 class="mt-4 font-weight-bold">Osobní doklad</h5>
        <id-card-set
            :client-data="representative"
            :show-header="false"
        />
    </div>
</template>

<script>
import DetailSetBase from './DetailSetBase.vue'
import IdCardSet from './IdCardSet.vue'
import { countries } from '@cyrrus/locale-utils'

export default {
    name: 'RepresentativeSet',
    extends: DetailSetBase,
    props: {
        representative: {
            type: Object,
            required: true
        }
    },
    components: {
        IdCardSet
    },
    computed: {
        taxDomicile () {
            return (this.representative.personal.taxDomicile) ? countries[this.representative.personal.taxDomicile] : null
        },
        politician () {
            return (this.representative.personal.politician) ? 'Ano' : 'Ne'
        }
    }
}
</script>
