<script>
import DetailItem from './DetailItem.vue'
import { CLIENT_TYPE_COMPANY, CLIENT_TYPE_SELFEMPLOYED, CLIENT_TYPE_PERSON } from '@cyrrus/clientdatastructure'

export default {
    name: 'DetailSetBase',
    components: {
        DetailItem
    },
    props: {
        clientData: {
            type: Object,
            required: true
        },
        horizontal: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        isCompany () {
            return this.clientData.type === CLIENT_TYPE_COMPANY
        },
        isPerson () {
            return this.clientData.type === CLIENT_TYPE_PERSON
        },
        isSelfemployed () {
            return this.clientData.type === CLIENT_TYPE_SELFEMPLOYED
        }
    }
}
</script>
