<template>
    <div>
        <h4>Systémové údaje</h4>
        <hr/>
        <detail-item
            title="ID"
            :content="clientData.dataOriginId"
        />
        <detail-item
            title="Capitol ID"
            :content="clientData.client.capitolId"
        />
        <detail-item
            title="CRM ID"
            :content="clientData.client.crmId"
        />
        <detail-item
            title="Původ"
            :content="dataOriginName"
        />
        <detail-item
            title="Typ"
            :content="clientData.typeName"
        />
        <detail-item
            title="Rezident ve státě OCP"
            :content="(clientData.client.residency) ? 'Ano' : 'Ne'"
        />
        <detail-item
            title="KIDC IZ"
            :content="(clientData.client.externalBrokerCapitolId) ? clientData.client.externalBrokerCapitolId : 'Ne'"
        />
        <detail-item
            v-if="clientData.client.externalBrokerCapitolId"
            title="KIDC IZ agent"
            :content="(clientData.client.externalBrokerAgentCapitolId) ? clientData.client.externalBrokerAgentCapitolId : 'Ne'"
        />
    </div>
</template>

<script>
import DetailSetBase from './DetailSetBase.vue'

export default {
    name: 'SystemSet',
    extends: DetailSetBase,
    computed: {
        dataOriginName () {
            if (this.clientData.dataOriginName === null || this.clientData.dataOriginName === undefined) {
                return 'Interní databáze'
            }
            return this.clientData.dataOriginName
        }
    }
}
</script>
