<template>
    <div>
        <div v-if="showDetail === null">
            <div
                v-if="clientData.company.representatives.length > 0"
                class="list-group"
            >
                <div
                    v-for="(representative, index) in clientData.company.representatives"
                    :key="representative.id"
                    class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                >
                    <div class="flex-grow-1">
                        {{representative.personal.fullName}} - {{representative.position}}
                    </div>
                    <div>
                        <b-btn
                            variant="primary"
                            size="sm"
                            @click.prevent="showDetail = index"
                        >
                            <span class="iconify" data-icon="mdi:eye" />
                        </b-btn>
                    </div>
                </div>
            </div>

            <p
                v-else
                class="alert alert-danger"
            >
                Nejsou zadáni žádní oprávnění zástupci společnosti.
            </p>
        </div>

        <representative-set
            v-else
            :clientData="clientData"
            :representative="selectedRepresentative"
            @close="showDetail = null"
        />
    </div>
</template>

<script>
import RepresentativeSet from './ClientDetailSets/RepresentativeSet.vue'

export default {
    name: 'RepresentativesList',
    components: {
        RepresentativeSet
    },
    props: {
        clientData: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            showDetail: null
        }
    },
    computed: {
        selectedRepresentative () {
            if (this.showDetail !== null) {
                return this.clientData.company.representatives[this.showDetail]
            }
            return null
        }
    }
}
</script>
