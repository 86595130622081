<template>
    <div>
        <slot name="header">
            <h5 class="mb-3 mt-4">{{$t('investmentCategories')}}</h5>
            <p v-if="!noHeader">
                <strong>{{$t('client')}}: {{clientInstance.fullName}}</strong>
            </p>
        </slot>
        <div class="alert alert-danger" v-if="categories.length === 0">
            {{$t('noActiveCategories')}}
        </div>
        <div class="list-group">
            <div
                v-for="item in categories"
                :key="item.uid"
                class="list-group-item"
            >
                <p class="mb-1">
                    <strong>
                        {{$t(`categories.${item.name}`)}}
                    </strong>
                </p>
                <small class="text-muted">
                    {{$t('added')}}: {{(new Date(item.added)).toLocaleString()}}, {{$t('uid')}}: {{item.uid}}
                </small>
            </div>
        </div>
    </div>
</template>

<script>
import { ClientData } from '@cyrrus/clientdatastructure'

export default {
    name: 'InvestCategoriesList',
    props: {
        client: {
            type: Object,
            required: true
        },
        noHeader: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        clientInstance () {
            return (this.client instanceof ClientData) ? this.client : new ClientData(this.client)
        },
        categories () {
            return (this.clientInstance.client.investmentCategories || [])
        }
    }
}
</script>

<i18n>
{
    "cz": {
        "client": "Klient",
        "investmentCategories": "Investiční kategorie klienta",
        "noActiveCategories": " Klient nemá žádné aktivní investiční kategorie. Vytvořte kategorie vyplněním investičního dotazníku.",
        "added": "Přidáno",
        "uid": "uid",
        "categories": {
            "bonds": "Dluhopisy",
            "stocks": "Akcie",
            "etf": "ETF",
            "funds": "Fondy",
            "fki": "FKI",
            "derivates": "Deriváty",
            "leveragedProducts": "Pákové produkty",
            "currencyForwards": "Měnové forwardy"
        }
    },
    "sk": {
        "client": "Klient",
        "investmentCategories": "Investičná kategórie klienta",
        "noActiveCategories": " Klient nemá žiadne aktívne investičné kategórie. Vytvorte kategórie vyplnením investičného dotazníka.",
        "added": "Pridané",
        "uid": "uid",
        "categories": {
            "bonds": "Dlhopisy",
            "stocks": "Akcie",
            "ETF": "ETF",
            "funds": "Fondy",
            "fki": "FKI",
            "derivates": "Deriváty",
            "leveragedProducts": "Pákové produkty",
            "currencyForwards": "Menové forwardy"
        }
    },
    "en": {
        "client": "Client",
        "investmentCategories": "Client's investment categories",
        "noActiveCategories": " The client has no active investment categories. Create categories by filling out the investment questionnaire.",
        "added": "Added",
        "uid": "uid",
        "categories": {
            "bonds": "Bonds",
            "stocks": "Stocks",
            "etf": "ETF",
            "funds": "Funds",
            "fki": "FKI",
            "derivates": "Derivatives",
            "leveragedProducts": "Leveraged Products",
            "currencyForwards": "Currency Forwards"
        }
    },
    "hr": {
        "client": "Klijent",
        "investmentCategories": "Klijentova kategorija ulaganja",
        "noActiveCategories": " Klijent nema aktivne investicijske kategorije. Napravite kategorije ispunjavanjem investicijskog upitnika.",
        "added": "Dodano",
        "uid": "uid",
        "categories": {
            "bonds": "Obveznice",
            "stocks": "Dionice",
            "etf": "ETF",
            "funds": "Sredstva",
            "fki": "FKI",
            "derivates": "Izvedenice",
            "leveragedProducts": "Proizvodi s polugom",
            "currencyForwards": "Forwards u valuti"
        }
    }
}
</i18n>
