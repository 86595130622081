<template>
    <div>
        <h4 v-if="showHeader">
            Doklad totožnosti
        </h4>
        <hr v-if="showHeader" />
        <p
            v-if="clientData.idCard.scanNotAllowed"
            class="text-danger"
        >
            <strong>Klient nesouhlasil s poskytnutím kopie OP.</strong>
        </p>
        <p
            v-if="!isValid"
            class="alert alert-danger"
        >
            Neplatný doklad totožnosti!
        </p>

        <p
            v-if="isValid && isNearValidityEnd"
            class="alert alert-warning"
        >
            Platnost dokladu totožnosti vyprší za {{daysLeft}} dní!
        </p>

        <detail-item
            title="Typ"
            :content="clientData.idCard.type"
        />
        <detail-item
            title="Číslo"
            :content="clientData.idCard.number"
        />
        <detail-item
            title="Vydal"
            :content="clientData.idCard.issuedBy"
        />
        <detail-item
            title="Země"
            :content="clientData.idCard.countryName"
        />
        <detail-item
            title="Platný do"
            :content="clientData.idCard.validity.toLocaleDateString()"
        />
    </div>
</template>

<script>
import DetailSetBase from './DetailSetBase.vue'

export default {
    name: 'IdCardSet',
    extends: DetailSetBase,
    props: {
        showHeader: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        daysLeft () {
            const dt1 = new Date()
            const dt2 = new Date(this.clientData.idCard.validity)
            return Math.floor(
                (
                    Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
                    Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())
                ) /
                (1000 * 60 * 60 * 24)
            )
        },
        isValid () {
            return this.daysLeft >= 0
        },
        isNearValidityEnd () {
            return this.daysLeft <= 31
        }
    }
}
</script>
