<template>
    <div :class="{ 'col-12 col-lg-4': horizontal }">
        <h4>Osobní údaje</h4>
        <hr/>
        <detail-item
            title="Typ klienta"
            :content="clientData.typeName"
        />
        <detail-item
            title="Jméno a příjmení / Firma"
            :content="clientData.fullName"
        />
        <detail-item
            title="Domicil"
            :content="taxDomicile"
            v-if="!isCompany"
        />
        <detail-item
            title="Datum narození"
            :content="clientData.personal.dateOfBirth.toLocaleDateString()"
            v-if="!isCompany"
        />
        <detail-item
            title="RČ"
            :content="(clientData.personal.noBirthNumber) ? 'Nemá RČ' : clientData.personal.birthNumber"
            v-if="!isCompany"
        />
        <detail-item
            v-if="!clientData.personal.noBirthNumber && !isCompany"
            title="Země vydání RČ"
            :content="clientData.personal.birthNumberCountryName"
        />
        <detail-item
            title="Adresa"
            :content="clientData.address.address.fullAddress"
        />
        <detail-item
            v-if="clientData.address.useMailingAddress"
            title="Korespondenční adresa"
            :content="clientData.address.mailingAddress.fullAddress"
        />
    </div>
</template>

<script>
import DetailSetBase from './DetailSetBase.vue'
import { countries } from '@cyrrus/locale-utils'

export default {
    name: 'PersonalSet',
    extends: DetailSetBase,
    computed: {
        taxDomicile () {
            return (this.clientData.personal.taxDomicile) ? countries[this.clientData.personal.taxDomicile] : null
        }
    }
}
</script>
