<template>
    <div>
        <h4>Bankovní účty</h4>
        <hr/>
        <div
            v-for="account in clientData.bankAccounts"
            :key="account.id"
        >
            <detail-item
                v-if="!account.foreign"
                title="Číslo účtu"
                :content="`${account.prefix}-${account.account}/${account.code}`"
            />
            <template v-else>
                <detail-item
                    title="Název účtu"
                    :content="account.accountName"
                />
                <detail-item
                    title="IBAN"
                    :content="account.iban"
                />
                <detail-item
                    title="SWIFT"
                    :content="account.swift"
                />
                <detail-item
                    title="Banka"
                    :content="account.bankNameAddress"
                />
            </template>
            <detail-item
                title="Měna účtu"
                :content="account.currency"
            />
        </div>
    </div>
</template>

<script>
import DetailSetBase from './DetailSetBase.vue'

export default {
    name: 'PersonalSet',
    extends: DetailSetBase
}
</script>
