<template>
    <div class="list-group-item">
        <p class="mb-1">
            <strong>
                {{profile.name}}
            </strong>
        </p>
        <small class="text-muted">
            Přidán: {{(new Date(profile.added)).toLocaleString()}}, uid: {{profile.uid}}
        </small>
    </div>
</template>

<script>
export default {
    name: 'InvestProfilesListItem',
    props: {
        profile: {
            type: Object,
            required: true
        }
    }
}
</script>
