<template>
    <div :class="{ 'row': horizontal }">
        <div :class="{ 'col-12 col-lg-4': horizontal }">
            <p v-if="clientData && clientData.client.externalBrokerCapitolId" class="alert alert-warning">
                Tento klient je od IZ: {{clientData.client.externalBrokerCapitolId}}
            </p>
            <personal-set
                :client-data="clientData"
            />
            <contact-set
                class="mt-4"
                :client-data="clientData"
            />
        </div>
        <div :class="{ 'col-12 col-md-4': horizontal }">
            <invest-profiles-list
                :client="clientData"
            >
                <template #header>
                    <h4 :class="{ 'mt-4': !horizontal }">
                        Investiční profily
                    </h4>
                    <hr/>
                </template>
            </invest-profiles-list>
            <invest-categories-list
                :client="clientData"
            >
                <template #header>
                    <h4 :class="{ 'mt-4': !horizontal }">
                        {{$t('investmentCategories')}}
                    </h4>
                    <hr/>
                </template>
            </invest-categories-list>
            <id-card-set
                class="mt-4"
                :client-data="clientData"
                v-if="!isCompany"
            />
            <company-set
                class="mt-4"
                v-if="!isPerson"
                :client-data="clientData"
            />
        </div>
        <div :class="{ 'col-12 col-md-4': horizontal }">
            <bank-account-set
                :class="{ 'mt-4': !horizontal }"
                :client-data="clientData"
            />
            <system-set
                class="mt-4"
                :client-data="clientData"
            />
        </div>
    </div>
</template>

<script>
import PersonalSet from './ClientDetailSets/PersonalSet.vue'
import CompanySet from './ClientDetailSets/CompanySet.vue'
import IdCardSet from './ClientDetailSets/IdCardSet.vue'
import SystemSet from './ClientDetailSets/SystemSet.vue'
import BankAccountSet from './ClientDetailSets/BankAccountSet.vue'
import ContactSet from './ClientDetailSets/ContactSet.vue'
import InvestProfilesList from './InvestProfilesList.vue'
import InvestCategoriesList from './ClientDetailSets/InvestCategoriesList.vue'

export default {
    name: 'ClientDetailSet',
    components: {
        InvestProfilesList,
        PersonalSet,
        CompanySet,
        IdCardSet,
        SystemSet,
        BankAccountSet,
        ContactSet,
        InvestCategoriesList
    },
    props: {
        clientData: {
            required: true
        },
        horizontal: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        isCompany () {
            return this.clientData.isCompany
        },
        isPerson () {
            return this.clientData.isPerson
        }
    }
}
</script>

<i18n>
{
    "cz": {
        "clientIZ": "Tento klient je od IZ",
        "investmentProfiles": "Investiční profily",
        "investmentCategories": "Investiční kategorie"
    },
    "sk": {
        "clientIZ": "Tento klient je od IZ",
        "investmentProfiles": "Investičné profily",
        "investmentCategories": "Investičné kategórie"
    },
    "en": {
        "clientIZ": "This client is from IZ",
        "investmentProfiles": "Investment Profiles",
        "investmentCategories": "Investment Categories"
    },
    "hr": {
        "clientIZ": "Ovaj klijent je iz IZ",
        "investmentProfiles": "Investicijski profili",
        "investmentCategories": "Kategorije ulaganja"
    }
}
</i18n>
