<template>
    <div>
        <div class="container-fluid">
            <h1>{{$t('contractDetail.heading', { sessionId })}}</h1>
        </div>

        <div class="container-fluid mt-4">
            <session-detail
                :session-id="sessionId"
            />
        </div>
    </div>
</template>

<script>
import SessionDetail from './Components/SessionDetail.vue'
export default {
    components: { SessionDetail },
    name: 'Detail',
    props: {
        sessionId: {
            type: String,
            required: true
        }
    }
}
</script>

<i18n>
{
    "cz": {
        "contractDetail": {
            "heading": "Detail sezení {sessionId}",
            "subtitle": ""
        }
    },
    "en": {
    }
}
</i18n>
