<template>
    <data-table
        :auto-update="false"
        :header="header"
        :loading="loading"
        :data="dataset"
        :buttons="buttons"
        lang="cs_CZ"
        :paging="false"
        @download="onDownload"
    />
</template>

<script>
import filesize from 'filesize'
const fileTypes = {
    idCard: 'Doklad',
    secondIdCard: 'Druhý doklad',
    bankAccountStatement: 'Výpis',
    other: 'Ostatní'
}

export default {
    name: 'FilesDataTable',
    props: {
        dataset: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        header () {
            return [
                {
                    text: 'Název',
                    data: 'filename',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Typ',
                    data: 'type',
                    format (value) {
                        return (fileTypes[value]) ? fileTypes[value] : value
                    },
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Velikost',
                    data: 'size',
                    format: filesize,
                    sortable: true,
                    filterable: true
                }
            ]
        },
        buttons () {
            return [
                {
                    event: 'download',
                    variant: 'primary',
                    text: 'Stáhnout'
                }
            ]
        }
    },
    methods: {
        onDownload (row) {
            this.$emit('download', row)
        }
    }
}
</script>
